export const uploadFormStyles = {
    padding: {
        md: "30px 0px 0px 20px",
        sx: "0px 0px 0px 0px"
    },

    '& .font-light-wrapper': {
        fontSize: '16px',
        fontWeight: '500px',
        fontFamily: 'Montserrat-light',
        '& span': {
            fontWeight: '400px',
            fontFamily: 'Montserrat',
        }
    },

};

export const uploadUpdateBackStyles = {
    position: 'fixed',
    padding: { xs: '105px 0px 0px 10px', md: '125px 0px 0px 10px', sm: '125px 0px 0px 10px' },
    width: '100%',
    background: 'white',
    zIndex: 2,
    '& .upload-back-button': {
        display: 'flex', alignItems: 'center', cursor: 'pointer',
        '& .icon': {
            width: { xs: '23px', md: '30px' },

        },
    },
    '& .MuiTypography-body1': {
        color: '#000',
        fontFamily: 'Montserrat-light',
        fontStyle: 'normal',
        fontSize: { md: '28px', sm: "20px", xs: '18px' },
        fontWeight: 300,
        lineHeight: 'normal',
    },
    '& .MuiTypography-body2': {
        color: '#000',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    '& .MuiTypography-h6': {
        color: '#000',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        fontSize: { xs: '16px', sm: '18px', md: '24px' },
    },

    '& .MuiCardContent-root': {
        '&.view3d-wrapper': {
            width: '100%',
            height: '100vh',
        }

    }



};

export const ProductNewPageStyles = {
    padding: { md: '140px 0px 0px 0px', sm: '160px 0px 0px 0px', xs: '110px 0px 0px 0px' },

    '& .divider-container': {
        display: { xs: 'none', md: 'block', sm: 'block' },

        '& .MuiDivider-root': {
            height: "92%"
        },
    },

    '& .font-light-wrapper': {
        fontSize: '16px',
        fontWeight: '500px',
        fontFamily: 'Montserrat-light',
        '& span': {
            fontWeight: '400px',
            fontFamily: 'Montserrat',
        }
    },
    '& .description-content': {
        display: "flex", padding: { md: '10px 35px 0px 90px', xs: '10px 20px 10px 10px' }
    },
    '& .upload-buttons': {
        justifyContent: "flex-end",
        padding: { md: '30px 30px 0px 0px', xs: '30px 20px 0px 0px' },

        '& .btn-1': {
            border: "1px solid black", color: "black", width: "110px", textTransform: "none"
        },
        '& .btn-2': {
            width: "110px", textTransform: "none", boxShadow: 'none !important'
        }
    }

};


export const ImageUploadComponentStyles = {
    '& .glp-wrapper-container': {
        maxWidth: "100%", boxShadow: 0, border: 0, padding: "0px", height: "300px", background: "#ffffff",

        '& .glp-box': {
            width: "100%", height: "100%",
            position: 'relative'
        },
    },

    '& .card-content': {
        textAlign: 'center'
    },
    '& .glb-close-icon': {
        position: 'absolute',
        marginTop: '10px',
        left: { xs: '90%', md: '95%', sm: '90%' },
        zIndex: 1,
        cursor: 'pointer'
    },
    '& .upload-btn': {
        boxShadow: 'none !important',
        width: '80px'
    },

    '& .img-input': {
        display: 'none'
    },


    '& .image-wrapper-container': {
        marginTop: "1px", display: "flex", justifyContent: "space-between",

        '& .image-card': {

            maxWidth: "100%",
            boxShadow: 0,
            border: 0,
            padding: "0px",
            height: "200px",
            background: "#ffffff",
            textAlign: "center",
            position: "relative",


        },
        '& .image-cancel-icon': {

            position: "absolute",
            justifyContent: "end",
            width: "20px",
            right: 0,
            top: "5px",
            background: "transparent",
        },

        '& .image-box': {
            width: "100%", height: "100%",
        }
    }

};

