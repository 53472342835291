import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import aadService from '../authProvider';

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

const instance = axios.create();
export const apiGet = async (url: string) => instance({
    method: 'get',
    url: baseURL + url,
});

export const apiGetWithBody = async (url: string, data: any) => instance({
    method: 'get',
    url: baseURL + url,
    data,
});

export const apiPost = async (url: string, data: any) => instance({

    method: 'post',
    url: baseURL + url,
    data,
});

export const apiPut = async (url: string, data: any) => instance({
    method: 'put',
    url: baseURL + url,
    data,
});

export const apiDelete = async (url: string) => instance({
    method: 'delete',
    url: baseURL + url,
});

instance.interceptors.request.use(function (config: any) {

    document.body.classList.add('loading-indicator');
    const token = sessionStorage.getItem('AccessToken');
    config.headers['Access-Control-Allow-Origin'] = "*";
    config.headers['Access-Control-Allow-Headers'] = "Origin, X-Requested-With, Content-Type, Accept";
    config.headers['Content-type'] = "application/json; charset=UTF-8";
    if (config.url.endsWith('image/upload')) {
        config.headers['Content-type'] = "multipart/form-data";
    }
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, function (error: any) {
    return Promise.reject(error);
});
instance.interceptors.response.use(
    function (response: any) {
        // if (response.config.method !== 'get' &&response.data.statusCode === 200) {
        //     toast.success(response.data.statusMessage, {
        //       position: toast.POSITION.TOP_RIGHT
        //     });
        //   }
        if (response.data) {
            if (response.data.statusCode === 200 || response.data.statusCode === 201) {
                return response.data;
            }
            return Promise.reject(response);
        }
        return Promise.reject(response);
    }, async function (error: any) {
        const originalRequest = error.config;
        if (error.response.status === 403 && !originalRequest._retry || error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = sessionStorage.getItem('refreshToken');
                const response = await axios.post(`${baseURL}auth/refresh?token=${refreshToken}`,);
                const { token } = response.data;

                sessionStorage.setItem('AccessToken', token);

                // Retry the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${token}`;
                return axios(originalRequest);
            } catch (error) {
                // Handle refresh token error or redirect to login
            }
        }
        document.body.classList.remove('loading-indicator');
        return Promise.reject(error.response.data.message);
    }
);

