export const useSearchStyles = {
    padding: { md: '140px 23px 10px 0px', xs: '110px 23px 10px 0px', sm: '125px 23px 10px 0px' },
    display: { sx: '', md: "flex" },
    background: 'white',
    paddingLeft: { lg: '50px' },
    position: 'fixed',
    alignItem: 'center',
    textAlign: 'center',
    zIndex: '2',
    '& button': {
        textTransform: "capitalize",
        padding: "10px 28px",
        borderRadius: "8px",
        marginTop: { xs: '10px', sm: '5px', md: "0px" }
    },

} as const;


export const useProductListStyles = {
    position: 'relative',
    padding: { xs: '250px 8px 0px 20px', md: '220px 8px 30px 20px', sm: '260px 8px 0px 20px' }, // Add some bottom padding to reserve space
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
        width: '0.5em',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
    },

    '& .MuiCard-root': {
        background: 'linear-gradient(149.1deg, #E6E6E6 0%, rgba(255, 255, 255, 0) 100%)',
        boxShadow: '0px',
        '&:hover': {
            // boxShadow: '1px 2px 6px 4px rgba(0, 0, 0, 0.2)',
            transform: 'scale(1.1)',
            boxShadow: '21.28302001953125px 21.28302001953125px 46.11320877075195px 0px rgba(0, 0, 0, 0.25)',
            animationTimingFunction: 'ease-out',
            animationDuration: '300ms',
        },
        '& .card-image': {
            cursor: 'pointer',
            height: { xl: '340px', lg: '250px', md: '300px', sm: '300px', xs: '300px' },
            objectFit: 'fill'
        },

        '& .MuiCardContent-root': {

            '& .MuiTypography-body1': {
                color: '#000',
                fontSize: '18px',
                fontStyle: 'normal',
                lineHeight: 'normal',


            },
            '& .MuiTypography-body2': {
                color: '#000',
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',

            },

        },
        '& .MuiCardHeader-root': {
            color: "#0062be",
            cursor: "pointer",
            '& .MuiCardHeader-title': {
                color: '#000',
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',

            },
            "& .compareicon": {
                width: { xs: '45px', md: 'initial', sm: 'initial' },
                height: { xs: '45px', md: 'initial', sm: 'initial' },
                padding: { xs: '7px', md: 'initial', sm: 'initial' }

            },
        },

        '& .MuiCardMedia-root': {
            borderRadius: '6px'
        },

        '& .truncate-text': {
            whiteSpace: { xs: 'nowrap', md: 'initial', sm: 'initial' },
            overflow: { xs: 'hidden', md: 'initial', sm: 'initial' },
            textOverflow: { xs: 'ellipsis', md: 'initial', sm: 'initial' },
            maxWidth: { xs: '100px', md: 'initial', sm: 'initial' },
        }
    }


} as const;

export const useFilterStyles = {
    padding: { xs: '0px 10px 0px 30px', lg: '100px 0px 0px 30px' },

    '& .MuiGrid-root': {
        padding: "10px 17px 11px 10px;",

    },

    '& .filter-wrapper': {
        //borderRight: '3px solid #3161BE',
        overflowY: 'overlay',
        height: '-webkit-fill-available',
        position: 'fixed',
        scrollbars: 'hidden'

    },
    '& .filter-wrapper-fox': {
        overflowY: 'auto',
        height: '72vh',
        position: 'fixed',
        overflow: 'hidden',
        scrollbarWidth: 'none',
        paddingBottom : '2%' //22-11-23
    },
    '& .header-title': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        padding: { lg: '20px 30px 0px 0px', md: '0px 30px 0px 0px' }

    },
    '& .header-title .MuiTypography-h6': {
        color: '#000',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '48px',
        fontSize: '20px'
    },

    '& .header-title img': {
        width: '30px',

    },

    '& .dropdown-fields': {
        '& .MuiListItemButton-root': {
            marginLeft: '23px',
            padding: '0px',
        }
    },

    '& .MuiList-root': {
        marginLeft: { lg: '-17px' },
        width: { lg: '15vw', xs: '100%' }
    },

    '& .MuiListItemButton-root': {
        '&:hover': {
            background: 'none', /* Set the desired background color for hover state */
        },

        '& .MuiTypography-body1': {
            color: '#000',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '36px',
            fontSize: '16px',

        },
        '& .MuiTypography-body2': {
            color: '#000',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '24px',
            textWrap: 'balance'
        },
    },
    '& .MuiFormControlLabel-root': {
        '& .MuiTypography-body2': {
            color: '#000',
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
        },

    }
} as const;


export const useCompareStyles = {
    position: 'fixed',
    bottom: '0',
    paddingLeft: '1%',
    paddingRight: '1%,',
    width: '100%',
    '& .close-icon': {
        width: '20px',
        cursor: 'pointer'

    },
    '& .compare-img': {
        '& img': {
            width: '50px',

        },

    },



} as const;

