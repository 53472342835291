import { Box, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TypeAHeadAutocompleteInput from '../commons/typeahead-input';
import { memo } from "react";
import { useSearchStyles } from '../../styles/index-component.style';
import useMediaQuery from '@mui/material/useMediaQuery';
import { mediaQueryXs } from "../../services/media-query-xs";
import { role } from "../../services/session";

const SearchBarComponent = ({
    fetchSuggestions,
    handleSearch,
    suggestions,
    setOperators,
    operators,
    toggleDrawer,
    filterOpen,
    type,
    fetchProductList
}: any) => {
    const router = useNavigate();
    const roles: any = role();
    const tabView: any = useMediaQuery('(min-width:900px)');

    return (
        <Box pt={2} width={{ md: filterOpen ? "81%" : "98%", xs: '100%' }} sx={useSearchStyles}>
            {mediaQueryXs() && tabView ? (
                <Grid container spacing={0}>
                    <Grid sx={{ paddingLeft: { lg: filterOpen ? '7vw' : '23vw' }, paddingRight: { lg: filterOpen ? '4vw' : '1vw' }, marginLeft: '-2vh', marginBlockStart: '-2px' }} item xs={7}>
                        <TypeAHeadAutocompleteInput fetchProductList={fetchProductList} setValue={setOperators} value={operators.search} handleTypeaHeadApi={fetchSuggestions} onValueChange={handleSearch} options={suggestions} />
                    </Grid>
                    <Grid sx={{ marginTop: '-5px', justifyContent: 'end', display: 'flex' }} item xs={5}>
                        {roles === 'SuperAdmin' && (
                            <>
                                <Button
                                    size="small"
                                    onClick={() => router(`/product/${type}/0`)}
                                    sx={{
                                        width: '55%',
                                        padding: '-1vh',
                                    }}
                                    variant="outlined"
                                >
                                    Upload New Product
                                </Button>
                            </>
                        )}

                        {roles === 'Admin' && (
                            <>
                                <Button
                                    onClick={() => router(`/product/${type}/0`)}
                                    sx={{
                                        width: '55%',
                                        padding: '2vh',
                                    }}
                                    variant="outlined"
                                    size="small"
                                >
                                    Upload New Product
                                </Button>
                            </>
                        )}

                        {(roles === 'develop' || roles === 'Employee') && (
                            <>
                                {/* <Button
                                    onClick={() => router(`/product/${type}/0`)}
                                    sx={{
                                        width: '55%',
                                        padding: '2vh',
                                    }}
                                    variant="outlined"
                                    size="small"
                                >
                                    Upload New Product
                                </Button> */}
                            </>


                        )}
                    </Grid>
                </Grid>
            ) : (
                <Grid mt={1} pl={3} container spacing={1}>
                    <Grid xs={11}>
                        <TypeAHeadAutocompleteInput fetchProductList={fetchProductList} setValue={setOperators} value={operators.search} handleTypeaHeadApi={fetchSuggestions} onValueChange={handleSearch} options={suggestions} />
                    </Grid>
                    <Grid xs={1}>
                        <img onClick={toggleDrawer(true)} alt="filter icon" width={30} height={30} src="/assets/filter.svg" />
                    </Grid>

                    {roles === 'SuperAdmin' && (
                        <>
                            <Button
                                onClick={() => router(`/product/${type}/0`)}
                                sx={{
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    width: '100%',
                                    textTransform: 'capitalize',
                                    padding: '4px 28px',
                                    borderRadius: '8px',
                                    marginTop: '20px !important',
                                }}
                                variant="outlined"
                            >
                                Upload New Product
                            </Button>
                        </>
                    )}

                    {roles === 'Admin' && (
                        <>
                            <Button
                                onClick={() => router(`/product/${type}/0`)}
                                sx={{
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    width: '100%',
                                    textTransform: 'capitalize',
                                    padding: '4px 28px',
                                    borderRadius: '8px',
                                    marginTop: '20px !important',
                                }}
                                variant="outlined"
                            >
                                Upload New Product
                            </Button>
                        </>
                    )}

                    {(roles === 'Employee' || roles === 'develop') && (
                        <>
                            {/* <Button
                                onClick={() => router('/barcodeListEmp')}
                                sx={{
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    width: '100%',
                                    textTransform: 'capitalize',
                                    padding: '4px 28px',
                                    borderRadius: '8px',
                                    marginTop: '20px !important',
                                }}
                                variant="outlined"
                            >
                                Barcode Request
                            </Button> */}
                        </>
                    )}
                </Grid>
            )}
        </Box>
    );
};

export default memo(SearchBarComponent);
