import React, { useState } from 'react';
import './pagination.css';

interface CustomPaginationProps {
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ totalItems, itemsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleClick = (page: number): void => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const renderPageNumbers = (): JSX.Element[] => {
    const pageNumbers: JSX.Element[] = [];
    const displayRange = 5;
    const rangeOffset: number = Math.floor(displayRange / 2);

    const totalPages: number = Math.ceil(totalItems / itemsPerPage);

    if (totalPages <= displayRange) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <span
            key={i}
            className={currentPage === i ? 'active' : ''}
            onClick={() => handleClick(i)}
          >
            {i}
          </span>
        );
      }
    } else {
      let startPage: number, endPage: number;

      if (currentPage <= rangeOffset) {
        startPage = 1;
        endPage = displayRange - 2;
      } else if (currentPage + rangeOffset >= totalPages) {
        startPage = totalPages - (displayRange - 3);
        endPage = totalPages;
      } else {
        startPage = currentPage - rangeOffset;
        endPage = currentPage + rangeOffset - 1;
      }

      if (startPage > 1) {
        pageNumbers.push(
          <span key={1} onClick={() => handleClick(1)}>
            {1}
          </span>
        );
        pageNumbers.push(<span key="startEllipsis">...</span>);
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <span
            key={i}
            className={currentPage === i ? 'active' : ''}
            onClick={() => handleClick(i)}
          >
            {i}
          </span>
        );
      }

      if (endPage < totalPages) {
        pageNumbers.push(<span key="endEllipsis">...</span>);
        pageNumbers.push(
          <span key={totalPages} onClick={() => handleClick(totalPages)}>
            {totalPages}
          </span>
        );
      }
    }

    if (currentPage > 1) {
      pageNumbers.unshift(
        <span key="prev" onClick={() => handleClick(currentPage - 1)}>
          &lt;
        </span>
      );
    }

    if (currentPage < totalPages) {
      pageNumbers.push(
        <span key="next" onClick={() => handleClick(currentPage + 1)}>
          &gt;
        </span>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="pagination">
      {renderPageNumbers()}
    </div>
  );
};

export default CustomPagination;
