

export const browserNavigate = () => {
    const browsers = [
        { name: 'Chrome', url: 'https://www.google.com/' },
        { name: 'Firefox', url: 'https://www.mozilla.org/' },
        { name: 'Edge', url: 'https://www.microsoft.com/en-us/edge' },
        { name: 'Safari', url: 'https://www.apple.com/' },
    ];
    const browserUrl = browsers.find((browser => new RegExp(browser.name).test(navigator.userAgent)));
    if (browserUrl) {
        window.location.href = browserUrl.url;
    }


};

export const findBrowser = () => {
    const browsers = [
        { name: 'Chrome', url: 'https://www.google.com/' },
        { name: 'Firefox', url: 'https://www.mozilla.org/' },
        { name: 'Edge', url: 'https://www.microsoft.com/en-us/edge' },
        { name: 'Safari', url: 'https://www.apple.com/' },
    ];
    const browserUrl = browsers.find((browser => new RegExp(browser.name).test(navigator.userAgent)));
    if (browserUrl) {
        return browserUrl.name;
    }

};