import { Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { role } from "../../services/session";
import Box from "../../icons/box.png";
import Barcode from "../../icons/barcode.png";



const Homepage = () => {
    const router = useNavigate();
    const roles: any = role();

    return (
        <div style={{ backgroundImage: `url(${require('../../icons/bg.png')})`, backgroundSize: 'fill' }}>
            <div style={{ display: 'flex', marginTop: '8%', flexDirection: 'column', marginLeft: '30%' }}>
                <div style={{ display: 'flex', border: '2px solid #f0f0f0', boxShadow: '0px 14px 14px 0px rgba(168, 168, 168, 0.6)', width: '60%', paddingBlock: '4vh', borderRadius: '8px', paddingInline: '1vh' }}>
                    <div>
                        <img src={Box} style={{ height: '160px', width: '200px' }} alt="boxImg" />
                    </div>
                    <div>
                        <Typography sx={{ marginBlockStart: '1vh', fontFamily: 'Montserrat', fontWeight: 'bold' }} variant="h5">Packaging Library</Typography>
                        <Typography variant='body2' sx={{ marginBlock: '1vh' }}>Unlock seamless access to vendor details with an intuitive packaging portal. Effortlessly search and manage materials with cutting-edge efficiency and up-to-date insights</Typography>
                        <Button
                            size='small'
                            variant='contained'
                            sx={{ marginBlockStart: '1vh', textTransform: 'none', paddingInline: '5vh', borderRadius: '7px' }}
                            onClick={() => router('/productView')}>View</Button>
                    </div>
                </div>
                <br />
                <div>
                    <div style={{ display: 'flex', border: '2px solid #f0f0f0', boxShadow: '0px 14px 14px 0px rgba(168, 168, 168, 0.6)', width: '60%', paddingBlock: '4vh', borderRadius: '8px', paddingInline: '1vh' }}>
                        <div>
                            <img src={Barcode} style={{ height: '160px', width: '200px' }} alt="barcode" />
                        </div>
                        <div>
                            <Typography variant='h5' sx={{ marginBlockStart: '1vh', fontFamily: 'Montserrat', fontWeight: 'bold' }}>Barcode Management</Typography>
                            <Typography variant='body2' sx={{ marginBlock: '1vh', marginInlineEnd: '4.5vh' }}>Streamline product labeling effortlessly! Just assign a barcode and benefit from seamless integration and real-time tracking for new products</Typography>
                            <Button
                                size='small'
                                variant="contained"
                                onClick={() => {
                                    if (roles === 'SuperAdmin') {
                                        router('/barcodeRequest');
                                    } else if (roles === 'Employee' || roles === 'develop' || roles === 'Admin') {
                                        router('/barcodeListEmp');
                                    }
                                }}
                                sx={{ marginBlockStart: '1vh', textTransform: 'none', paddingInline: '5vh', borderRadius: '7px' }}
                            >
                                View
                            </Button>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
};

export default Homepage;
