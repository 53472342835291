import AutoCompleteComponent from "./autocomplete";
import TextFieldsInput from "./text-fields-input";

const FormInput = ({ handleFormInputChange, type, label, field, value, error, helperText, options, name, multiple, endLabel, placeholder }: any) => {
    switch (type) {
        case 'text':
        default:
            return (
                <TextFieldsInput
                    field={field}
                    size="small"
                    width="100%"
                    name={name}
                    type={type}
                    handleValue={handleFormInputChange}
                    value={value}
                    error={error}
                    helperText={helperText}
                    label={label}
                    endLabel={endLabel}
                    placeholder={placeholder}
                />);
        case 'autocomplete':
            return (
                <AutoCompleteComponent
                    options={options}
                    field={field}
                    multiple={multiple}
                    type="autocomplete"
                    size="small"
                    width="100%"
                    name={name}
                    handleValue={handleFormInputChange}
                    value={value}
                    error={error}
                    helperText={helperText}
                    label={label}
                    placeholder={placeholder}


                />
            );
    }


};

export default FormInput;