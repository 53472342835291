import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastComponent = ({ message, type }: any) => {
    if (type === 'success') {
        return toast.success(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    } else if (type === 'error') {
        return toast.error(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
};

