import { createTheme, } from '@mui/material';
import { grey, blue } from '@mui/material/colors';
<link href='//fonts.googleapis.com/css?family=Montserrat:thin,extra-light,light,100,200,300,400,500,600,700,800'
    rel='stylesheet' type='text/css'></link>;
export const theme = createTheme({
    typography: {
        fontFamily: '"Montserrat"',
        color: '#000000'
    },

    overrides: {
        MuiTypography: {
            headline: {
                paddingTop: '.7em',
                paddingBottom: '.7em',
                fontSize: '12px',
                fontWeight: 600,
                fontFamily: '"Montserrat"',
                color: '#000000'
            },
            title: {
                fontFamily: '"Montserrat"',
                fontWeight: 500,
                lineHeight: 1.5,
                fontSize: '1.1em',
                color: '#000000'
            },
            subheading: {
                fontFamily: '"Montserrat"',
                fontWeight: 600,

                color: '#000000'
            },
            caption: {
                fontFamily: '"Montserrat"',
                color: '#000000'
            },
            body1: {
                fontWeight: '600',
                paddingBottom: '0px',
                fontFamily: '"Montserrat"',
                color: '#000000'
            },
            body2: {
                fontWeight: '600',
                fontFamily: '"Montserrat"',
                color: '#000000'
            },
            h2: {
                fontWeight: '100',
                fontFamily: '"Montserrat"',
                color: '#000000'
            },


        },
        MuiDrawer: {
            paperAnchorTop: {
                overflow: 'inherit'
            }
        },
        MuiPaper: {},
        MuiInput: {
            focused: {
                transform: 'scaleX(1)'
            }
        }
    },
    palette: {
        primary: {
            light: '#3161BE',
            main: '#3161BE',
            contrastText: '#ffff',
        },
        text: {
            primary: "#000000"
        },
        light: '#0066ff',
        accent: blue,
        secondary: {
            light: '#29318A',
            main: '#29318A',
            contrastText: '#ffff',
        },
    }
});
export const darkTheme = createTheme({
    typography: {
        fontFamily: '"Montserrat"',
        //fontDisplay: 'swap'
    },
    overrides: {
        MuiTypography: {
            headline: {
                paddingTop: '.7em',
                paddingBottom: '.7em',
                fontSize: '1.5em',
                fontWeight: 600,
                fontFamily: '"Montserrat"'
            },
            title: {
                fontFamily: '"Montserrat"',
                fontWeight: 500,
                lineHeight: 1.5
            },
            subheading: {
                fontFamily: '"Montserrat"',
                fontWeight: 600
            },
            caption: {
                fontFamily: '"Montserrat"'
            },
            body1: {
                paddingBottom: '0px',
                fontFamily: '"Montserrat"'
            },
            body2: {
                fontWeight: 600,
                fontFamily: '"Montserrat"'
            }
        },
        MuiDrawer: {
            paperAnchorTop: {
                overflow: 'inherit'
            }
        },
        MuiPaper: {},
        MuiInput: {
            focused: {
                transform: 'scaleX(1)'
            }
        }
    },
    palette: {
        primary: {
            light: '#3161BE',
            main: '#3161BE',
            contrastText: '#3161BE',
        },
        text: {
            primary: "#000000"
        },
        type: 'dark',
        accent: grey,
        secondary: {
            light: '#29318A',
            main: '#29318A',
            contrastText: '#29318A',
        },
    }
});