import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Box
} from "@mui/material";
import View3D from "@egjs/react-view3d";
import "@egjs/react-view3d/css/view3d-bundle.min.css";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import _ from "lodash";
import { ImageUploadComponentStyles } from "../../styles/upload-component-style";
import { toastComponent } from "../commons/toast";
import { productFileUpload } from "../../services/product.service";
import { glbImageMsg, imageMsg, toastErr, imageMb } from "../../constants/form-constant";
import { toast } from "react-toastify";

const arrays = [1, 2, 3, 4];
const typoColor = {
  color: "rgba(103, 103, 103, 1)",
  fontSize: "0.7rem"
};

const typoBold = {
  fontWeight: 'bold'
};
const ImageUploadComponent = ({
  name,
  handleValue,
  productDetails,
}: any) => {
  const [selectedImages, setSelectedImages] = useState<any[]>([
    productDetails?.images,
  ]);


  const handleImageUpload = async (event: any, index: any) => {
    const regexGlb = new RegExp(/[^\s]+(.*?).(glb|Glb)$/);
    const regexImage = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|JPG|JPEG|PNG)$/);
    const { files, name } = event.target;
    try {
      if (files) {
        if (index === 5 ? regexGlb.test(files[0].name) == true : regexImage.test(files[0].name) == true) {
          const imageSize = index === 5 ? 5 * imageMb * imageMb : 2 * imageMb * imageMb;
          if (files[0].size >= imageSize) {
            const message = index === 5 ? glbImageMsg : imageMsg;
            const type = toastErr;
            toastComponent({ message, type });
          } else {
            document.body.classList.add('image-upload-loading');
            const formData: any = new FormData();
            formData.append("file", files[0]);
            await productFileUpload(formData).then((resData: any) => {
              if (resData.statusCode === 200) {
                document.body.classList.remove('image-upload-loading');
                const images = { id: index, url: resData.data, file: files[0] };
                handleValue(name, images);
              }
            }).catch((error) => {
              toast.error(error?.length !== 0 && error !== undefined ? error : `Internal Server Error`, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });

          }
        } else {
          const message = index === 5 ? "Invalid file type. Please upload a glb file." : "Invalid file type. Please upload a JPEG, PNG file.";
          const type = toastErr;
          toastComponent({ message, type });
        }
      }
    } catch (error) {
      toast.error(`Error in UploadingImage. `, {
        position: toast.POSITION.TOP_RIGHT,

      });
    }
  };

  const handleCloseImage = (index: any) => {
    // alert(index);
    const value = selectedImages.filter((item: any) => item.id == index);
    const images = value[0];

    handleValue(name, images);
    // const updatedImages = selectedImages.filter((item: any) => item.id !== index);
    // alert(updatedImages);
    // setSelectedImages(updatedImages);
  };

  useEffect(() => {
    setSelectedImages(productDetails?.images);
  }, [productDetails]);
  return (
    <Box sx={ImageUploadComponentStyles}>
      <Grid mt={3} xs={12} md={12} sm={12}>
        <Card className="glp-wrapper-container">
          <Box className="glp-box" sx={{ width: "100%", height: "100%" }}>
            <>
              {
                
                selectedImages.find((img) => img.id === 5)?.url &&
                selectedImages && selectedImages.some((img) => img.id === 5)
                ? (
                  <>
                    <HighlightOffIcon className="glb-close-icon" onClick={() => handleCloseImage(5)} />
                    <View3D
                     autoplay={true}
                     scrollable={true}
                     tag="div"
                      src={`${process.env.REACT_APP_BASE_URL_IMAGES}/${selectedImages.find((img) => img.id === 5)?.url}`}
                      poster="/public/vercel.svg"
                    />
                  </>
                ) : (
                  <>
                    <CardContent className="card-content">
                      <Typography sx={{ color: "rgba(103, 103, 103, 1)" }} variant="h6">Add Media</Typography>
                      <Typography mt={1} sx={typoColor}>
                        3D glb must be less than <span style={typoBold}>  5mb</span> and
                      </Typography>
                      <Typography mt={1} sx={typoColor}>
                        {" "}
                        photos must be less than<span style={typoBold}>  2mb </span>in size{" "}
                      </Typography>
                      <Box mt={4}>
                        <Button className="upload-btn" variant="contained" component="label">
                          Add
                          <input type="file" onChange={(e) => handleImageUpload(e, 5)} multiple className="img-input" accept=".glb" name="images" />
                        </Button>
                      </Box>

                    </CardContent>
                    {/* {errors.images && (
                  <span className="media-span-tag">{errors.images}</span>
                )} */}
                  </>
                )}
            </>
          </Box>
        </Card>
      </Grid>
      <Grid className="image-wrapper-container" container spacing={2}  >
        {
          _.map(arrays, (index: any) => (
            <Grid item xs={6} sm={6} md={3}>
              {
                selectedImages && selectedImages.some((img) => img.id === index) ? (
                  <>
                    <Card className="image-card">
                      <HighlightOffIcon className="image-cancel-icon" key={index} onClick={() => handleCloseImage(index)} style={{ cursor: 'pointer' }}/>
                      <img width={100} height={100} alt={`Image ${index}`} className="image-box" src={`${process.env.REACT_APP_BASE_URL_IMAGES}/${selectedImages.find((img) => img.id === index)?.url}`} />
                    </Card>
                  </>
                ) : (<>
                  <Card className="image-card"  >
                    <CardContent>
                      <Box mt={6}>
                        <Button className="upload_btn" component="label">
                          <img src="/assets/Upload_duotone.svg" alt="Image upload" width={50} height={50} />
                          <input type="file" className="img-input" onChange={(e) => handleImageUpload(e, index)} multiple accept="image/jpeg, image/png" name="images" />
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </>
                )
              }
            </Grid>
          ))
        }
      </Grid>
    </Box >

  );
};

export default ImageUploadComponent;


///////height and width based upload
// if (files) {
//   const image = document.createElement("img");
//   image.src = URL.createObjectURL(files[0]);
//   image.onload = () => {
//     const naturalWidth = image.naturalWidth;
//     const naturalHeight = image.naturalHeight;
//     /// image width and height based upload ///
//     // if (naturalWidth <= imageWidthHeight && naturalHeight <= imageWidthHeight) {
//     const imageSize = index === 5 ? 5 * 1024 * 1024 : 2 * 1024 * 1024;
//     if (files[0].size >= imageSize) {
//       const message = index === 5 ? glbImageMsg : imageMsg;
//       const type = "error";
//       ToastComponent({ message, type })
//     } else {
//       const images = { id: index, url: image.src, file: files[0] };
//       handleValue(name, images);
//     }

//     // } else {
//     //   const message = 'Image only accepted 200px  height and width ';
//     //   const type = "error";
//     //   ToastComponent({ message, type })

//     // }

//   }

// };
