export const formUploadFields = (values: any) => [
    { label: 'productAvailability', value: values?.productAvailability || [], type: 'autocomplete', field: 'Product Availability' },
    { label: 'productType', value: values?.productType || [], type: 'autocomplete', field: 'Product Type' },
    { label: 'bestSuite', value: values.bestSuite, type: 'autocomplete', field: 'Best Suited For' },
    { label: 'mouldingProcess', value: values.mouldingProcess, type: 'autocomplete', field: 'Moulding Process' },
    { label: 'material', value: values.material, type: 'autocomplete', field: 'Material' },
    { label: 'containerType', value: values.containerType, type: 'autocomplete', field: 'Container Type' },
    { label: 'shapes', value: values.shapes, type: 'autocomplete', field: 'Shape' },
    { label: 'skuRange', value: values.skuRange, type: 'autocomplete', field: 'SKU Range' },
    { label: 'overFillCapacity', value: values.overFillCapacity, type: 'text', field: 'Over Fill Capacity' },
    { label: 'weight', value: values.weight, type: 'text', field: 'Weight' },
    { label: 'vendorName', value: values.vendorName, type: 'text', field: 'Vendor Name' },
    { label: 'price', value: values?.price || '', type: 'text', field: 'Price' },
];


export const glbImageMsg = "GLB Image size must be less than 5 MB";
export const imageMsg = "Image size must be less than 2 MB";
export const imageWidthHeight = 280;
export const toastErr = "error";
export const toastSuc = "success";
export const imageMb = 1024;
export const urlMse = "Product Copied Successfully";
export const compareCopyMsg = 'Compare page url Copied Successfully ';





