import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { uploadUpdateBackStyles } from "../../styles/upload-component-style";
import { memo } from "react";
import { Button } from 'antd';
import { Link } from "react-router-dom";

const UploadUpdateBack = ({ page, handleClose, id }: any) => {
    // const router = useRouter();
    return (
        <>
            {/* <Box sx={uploadUpdateBackStyles}>
                <Grid alignItems={'center'} m={1} mb={2} md={12} container>
                    <Grid xs={2} sm={4} md={5}>
                        <Box className="upload-back-button" onClick={() => handleClose(id)}  >
                            <img alt="back icon" className="icon" width={30} height={30} src="/assets/icon/back_arrow.svg" />
                            <Typography variant="h6" onClick={() => handleClose(id)} className="back_text">
                                Back
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={10} sm={4} sx={{ paddingRight: { xs: '30px' }, justifyContent: { xs: 'end', md: 'normal' } }} display="flex" md={7}>
                        {
                            page && page == "upload" ?
                                <Typography variant="body1">Upload New Product</Typography> :
                                <Typography variant="body1">Update Product</Typography>
                        }
                        <Grid  display="flex" >
          <Button  type="primary" style={{ marginLeft: '100px' }}>
            Add Vendor
          </Button>
        </Grid>
                    </Grid>
                    


                </Grid>

            </Box> */}
      <Box sx={uploadUpdateBackStyles}>
  <Grid alignItems="center" m={1} mb={2} md={12} container>
   
    <Grid xs={2} sm={4} md={5}>
      <Box className="upload-back-button" onClick={() => handleClose(id)}>
        <img alt="back icon" className="icon" width={30} height={30} src="/assets/icon/back_arrow.svg" />
        <Typography variant="h6" onClick={() => handleClose(id)} className="back_text">
          Back
        </Typography>
      </Box>
    </Grid>

 
    <Grid xs={10} sm={8} md={7} display="flex" justifyContent={{ xs: 'flex-start', md: 'space-between' }}>

      {page && page === 'upload' ? (
        <Typography variant="body1">Upload New Product</Typography>
      ) : (
        <Typography variant="body1">Update Product</Typography>
      )}

      <Link to="/addVendor">
      <Button type="primary"  style={{ marginRight: '20px' }}>
  Add Vendor
</Button>
</Link>
    </Grid>
  </Grid>
</Box>
 

        </>
    );
};
export default memo(UploadUpdateBack);