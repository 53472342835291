import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchProductsDeleteById, fetchProductsById } from "../../../services/product.service";
import ProductDetailsComponent from "../../../components/product-detail-component/product-details";
import Context from "../../../hooks/context";
import { imageHandle } from "../../../services/image-handle.service";
import Modal from "../../../components/commons/modal";
import DeleteModal from "../../../components/commons/deleteModal";
import { toast } from "react-toastify";


const ProductDetail = () => {
    const router = useNavigate();
    let { type, id } = useParams();
    const contextData: any = useContext(Context);
    const [openMod, setOpenMod] = useState(false);
    const [openDelMod, setOpenDelMod] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [modal, setModal] = useState(false);
    const [image, setImages] = useState({
        glb: ''
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleImageModal = () => {
        setModal(true);
    };
    const handleClickBack = () => {
        router("/productView");
        window.location.reload();
    };
    const handleFetchData = async () => {
        const path = window.location.pathname;
        const regex = /\/product-detail\/(.*?)\/(.*)$/;
        const match = path.match(regex);
        if (match) {
            type = match[1];
            id = match[2];
        }
        await fetchProductsById({ id, type }).then((res: any) => {
            setData(res.data);
            const value: any = imageHandle(res?.data?.imageURLs);
            setImages(value);

        }).catch((error) => {
            toast.error(error?.length !== 0 && error !== undefined ? error : `Internal Server Error`, {
                position: toast.POSITION.TOP_RIGHT,

            });
        });

    };

    const handleEditProduct = (id: any) => {
        router(`/product/${type}/${id}`);
    };

    const handleClickCloseMain = async (label: any) => {
        if (label === 'yes') {
            await fetchProductsDeleteById({ id, type }).then(async (res: any) => {
                if (res?.statusCode === 200) {
                    setOpenDelMod(false);
                    setOpenMod(true);
                }
            }).catch((exception) => {
                console.log(exception);
            });
        } else if (label === 'back') {
            router('/productView');
        } else {
            setOpenDelMod(false);

        }
    };

    const handleClickCompare = (product: any) => {
        contextData.handleCompareProduct(product, "add");
        router('/productView');
    };

    const handleDeleteProduct = async () => {
        setOpenDelMod(true);
    };

    useEffect(() => {
        if (id) {
            handleFetchData();
        }
    }, [id]);

    return (
        <>   <ProductDetailsComponent handleDeleteProduct={handleDeleteProduct} image={image} handleClickCompare={handleClickCompare} data={data} handleClickBack={handleClickBack} anchorEl={anchorEl} setAnchorEl={setAnchorEl} open={open} handleClick={handleClick} handleClose={handleClose} handleImageModal={handleImageModal} setModal={setModal} modal={modal} handleEditProduct={handleEditProduct} />
            <DeleteModal handleClickCloseMain={handleClickCloseMain} openDelMod={openDelMod} />
            <Modal handleClose={() => handleClickCloseMain('back')} message={'Deleted Successful!'} content={'The product has been removed'} open={openMod} setOpen={setOpenMod} />
        </>



    );
};
export default ProductDetail;
