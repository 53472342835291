import { Grid, Typography, Box, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from '@mui/icons-material/Close';
import View3D from "@egjs/react-view3d";
import "@egjs/react-view3d/css/view3d-bundle.min.css";

const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  "&:focus": {
    outline: "none",
  },
  '& .modal-main-container': {
    right: 0, color: 'white', cursor: 'pointer', alignItems: 'center', position: 'fixed', top: '0', pr: '30px', pt: '30px'
  },
  '& .glpSlideImage': {
    width: { md: "80vw", sm: "80vw" }, height: { md: "76vh", sm: "76vh", xs: '64vh' }, textAlign: "center", alignItems: "center", postion: 'relative',
    '& .image': {
      borderRadius: "10px",
      width: { md: '100%', sm: '500px', xs: '275px' }, height: { md: '80%', sm: '500px', xs: '300px' },
      marginTop: "40px",
    }

  },
  "& .subImage": {
    margin: "0 auto", display: 'flex', alignItems: 'center', justifyContent: 'center'
  },
  "& .thumbImage": {
    borderRadius: "10px", width: { md: "100px", sm: '100px', xs: '60px' }, height: { md: "110px", sm: '110px', xs: '70px' },
    cursor: 'pointer'
  },
  "& .arrow-icon": {
    width: { md: '75px', sm: '75px', xs: '35px' }, height: { md: '100px', sm: '100px', xs: '35px' }, color: 'white',
    position: 'absolute', top: '34%', right: '90%'
  },
  "& .arrow-icon-two": {
    width: { md: '75px', sm: '75px', xs: '35px' }, height: { md: '100px', sm: '100px', xs: '35px' }, color: 'white',
    position: 'absolute', top: '34%', left: '90%'
  },
};

const modalStyle = {
  backdropFilter: "blur(0px)",
  // background:'black'

};

const ImageSlick = ({ modal, glbUrl, Images, setModal }: any) => {
  const [currentImgIndex, setCurrentImgIndex] = useState<any>('');
  const handleArrowClick = (currentIndex: any) => {
    if (currentIndex < 0) {
      setCurrentImgIndex(Images.length);
    } else if (currentIndex > Images.length) {
      setCurrentImgIndex(0);
    } else {
      setCurrentImgIndex(currentIndex);
    }
  };

  useEffect(() => {

    setCurrentImgIndex(0);
  }, []);

  return (
    <>
      <Modal sx={modalStyle} open={modal}  >
        <>
          <Box sx={style}>
            <Box className="modal-main-container" onClick={() => setModal(!modal)}>
              <Typography display='flex' variant="body2">Close <CloseIcon /></Typography>
            </Box>
            <Box  >
              <Grid mt={{ xs: 6, md: 0, sm: 0 }} sx={{ display: 'flex', justifyContent: 'center' }} className="glpSlideImage" p={3} md={12}>
                {
                  Images?.length === currentImgIndex ? (
                    <View3D autoplay={true} scrollable={true} tag="div" src={glbUrl} poster="/public/vercel.svg" />
                  ) : (
                    <Grid sx={{ width: { md: '500px', xs: '289px', sm: '500px' }, height: { md: '480px' } }} >
                      <img width={130} height={80} alt="3d image" className="image" src={Images[currentImgIndex]} />
                    </Grid>
                  )
                }
              </Grid>
              <KeyboardArrowLeftIcon className="arrow-icon" onClick={() => handleArrowClick(currentImgIndex - 1)} />
              <ChevronRightIcon className="arrow-icon-two" onClick={() => handleArrowClick(currentImgIndex + 1)} />
              <Grid mt={{ xs: 0 }} mb={10} className="subImage" md={12} xs={12}>
                {Images.map((img: any, index: any) => (
                  <Grid key={index} ml={1} xs={4}>
                    <img width={100} height={100} alt="thumb image" className="thumbImage" key={index} onClick={() => setCurrentImgIndex(index)} src={img} />
                  </Grid>
                ))}
                <Grid ml={1} xs={4}>
                  <img width={100} height={100} alt="3d image" className="thumbImage" onClick={() => setCurrentImgIndex(Images.length)} src="/assets/sample-products/360-degrees-icon.jpg" />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>



      </Modal>
    </>
  );
};
export default ImageSlick;