import { createProductList } from "./product.service";
import _ from "lodash";
import { INITIAL_PRODUCT_DETAILS } from "../constants/Initial_states";

import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
export const imageUploadHandle = async ({ validateForm, productDetails, setProductDetails, setModalContent, setOpen, label, setFormErrors }: any) => {
    let value: any;
    const valid = validateForm({ productDetails, setFormErrors });
    if (valid) {
        document.body.classList.add('image-upload-loading');
        if (label === 'Upload') {
            const id = { id: null };
            _.merge(productDetails, id);
        }
        value = productDetails;
        value.imageURLs = [];
        productDetails.images.forEach((element: any) => {
            value.imageURLs.push(element.url);
        });
        // delete value.images;
        await createProductList(productDetails.productType, productDetails).then((response: any) => {

            document.body.classList.remove('image-upload-loading');
            value = INITIAL_PRODUCT_DETAILS;
            value.images = [];
            setProductDetails(value);
            setModalContent(label);
            setOpen(true);

        }).catch((error) => {
            document.body.classList.remove('image-upload-loading');
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT,
            });
        });

    }
};