import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid, Typography, Button, Menu, MenuItem } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';

const icons = {
    width:'13px',
    cursor:'pointer',
    marginLeft:'5px'

};
const closeContents = {
    justifyContent:'flex-end',
    width:'90%',
    alignItems:'center',
};


const close = {
    fontSize:'11px',
    alignItems:'center',
    cursor:'pointer',
    color:'red'

};
const menuBox = {
    marginLeft:{md:'-75px',xs:'-25px',sm:"-55px"},
    marginTop:'-25px',
    cursor:'pointer',
    alignItems:'center',
    boxShadow:'0px 8px 12px 0px rgba(0, 0, 0, 0.25)'

};

const menuButton = {
    width:'120px',fontSize:'11px',
    borderRadius:'8px',
    textTransform : 'none'
};




const EditAndDelete = ({handleDeleteProduct,data,handleEditProduct}: any) => {
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Grid  >
            <MoreVertIcon cursor="pointer" onClick={handleClick} />
            <Menu sx={menuBox} id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ "aria-labelledby": "basic-button", }}>
               <Grid container sx={closeContents}>
               <Typography onClick={handleClose} sx={close} >Close  </Typography>
               <ClearIcon onClick={handleClose} style={icons} />
               </Grid>
                <MenuItem >
                    <Button onClick={()=>handleEditProduct(data.id)} sx={menuButton} variant="outlined">Edit<EditIcon sx={icons} /></Button>
                </MenuItem>
                <MenuItem >
                    <Button onClick={ ()=> handleDeleteProduct(data.id)} sx={menuButton}  color="error" variant="outlined">Delete<ClearIcon sx={icons} /> </Button>
                </MenuItem>
            </Menu>
        </Grid>
    );
};
export default EditAndDelete;