export const mouldingProcess = [
    { id: "Extrusion Blow Moulding", mouldingName: 'Extrusion Blow Moulding' },
    { id: "Injection Blow Moulding", mouldingName: 'Injection Blow Moulding' },
    { id: "Injection Stretch Blow Moulding", mouldingName: 'Injection Stretch Blow Moulding' },
    { id: "Injection Moulding", mouldingName: 'Injection Moulding' },



];

export const productAvailability = [
    {
        id: "Now Available", productAvailabilityName: 'Now Available'
    },
    {
        id: "Coming Soon", productAvailabilityName: 'Coming Soon'
    }
];

export const productType = [
    {
        id: "international", productTypeName: 'international'
    },
    {
        id: "domestic", productTypeName: 'domestic'
    }
];