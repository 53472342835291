import React from 'react';
import Lock from '../../icons/lock_2.png';
import { Typography } from '@mui/material';

const UserNotFound = () => {

    return (
        <>
            <div style={{ display: 'flex', marginTop: '12%', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', marginInlineStart: '0' }}>
                    <img src={Lock} />
                </div>
                <br />
                <Typography variant="h5" sx={{textAlign:'center'}}>
                    "Oops! It seems you don't have access to the Packaging Library.<br/> Reach out to your admin to get things sorted!"
                </Typography>
            </div>
        </>
    );
};

export default UserNotFound;
