import _ from "lodash";
// export const validateForm = ({ productDetails, setFormErrors }: any) => {
//   const errors: any = {};
//   _.entries(productDetails).forEach(([fieldName, fieldValue]) => {

//     const label = fieldName;
//     // console.log(fieldName + fieldValue);
//     if (
//       !fieldValue ||
//       (Array.isArray(fieldValue) && fieldValue.length === 0)
//     ) {
//       if (fieldName !== 'images' && fieldName !=="id") { // Exclude the "image" field from the error check
//         errors[fieldName] = `${label} is required`;
//       }
//     }
//   });
//   setFormErrors(errors);
//   // console.log(errors);
//   // console.log(Object.keys(errors).length === 0);
//   return Object.keys(errors).length === 0;
// };
export const validateForm = ({ productDetails, setFormErrors }: any) => {
  const errors: any = {};
  
  if (!productDetails.productType) {
    errors.productType = "Product Type is required";
  }

  if (!productDetails.shapes || productDetails.shapes.length === 0) {
    errors.shapes = "Shapes are required";
  }

  setFormErrors(errors);
  
  return Object.keys(errors).length === 0;
};

// export const validateForm = ({ productDetails, setFormErrors }: any) => {
//   const errors: any = {};
//   setFormErrors(errors);
//   return true; 
// };