import { useState } from "react";
import { Box } from "@mui/system";
import { Grid, Typography, Button, Menu, MenuItem } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { productDetailsStyles } from "../../styles/product-detail-style";
import MenuIcon from '@mui/icons-material/Menu';
import { toastComponent } from "../commons/toast";
import { urlMse, toastSuc, compareCopyMsg } from "../../constants/form-constant";
import { pdfDownloader } from "../../services/pdf.services";
const menuList = {
    marginTop: '10px',

};


const ProductDetailsHead = ({ handleClickCompare, data, page }: any) => {
    const router = useNavigate();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const downloadPdfDocument = async () => {
        pdfDownloader(page);
        setAnchorEl(null);

    };
    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location.toString());
        setAnchorEl(null);
        const type = toastSuc;
        const message = page === 'compare' ? compareCopyMsg : urlMse;
        toastComponent({ message, type });

    };
    const handleClickBack = () => {
        router("/productView");
        // window.location.reload();
    };
    return (
        <>
            <Grid p={2} mt={1} xs={12} sm={12} md={12} sx={productDetailsStyles}>
                <Grid xs={12} sm={2} md={6}>
                    <Box onClick={() => handleClickBack()} className="back-icon">
                        <img className="icon" alt="back icon" width={30} height={30} src="/assets/icon/back_arrow.svg" />
                        <Typography variant="h5">Back</Typography>
                    </Box>
                </Grid>
                {
                    <>
                        <Grid container sx={{
                            display: { xs: 'none', md: 'flex', sm: 'flex' },}} justifyContent="flex-end" xs={12} sm={10} md={6}>
                            <Button onClick={downloadPdfDocument} sx={{ mr: { sm: '3px' } }} variant="outlined" className="button-style-productview">
                                Download
                                <img className="icons" alt="download icon" width={30} height={30} src="/assets/icon/download.svg" />
                            </Button>
                            <Button onClick={copyToClipboard} sx={{ mr: { sm: '3px' } }} variant="outlined" className="button-style-productview">
                                Share
                                <img className="icons" width={30} height={30} alt="share icon" src="/assets/icon/share.svg" />
                            </Button>
                            {
                                data?.length === 3 ? (<></>) : (
                                    <Button className="button-style-productview" onClick={() => handleClickCompare(data)} variant="outlined">
                                        Compare
                                        <img className="icons" alt="compare icon" width={30} height={30} src="/assets/icon/compage.svg" />
                                    </Button>

                                )
                            }

                        </Grid>
                        <Grid container sx={{ display: { md: 'none', sm: 'none' } }} justifyContent="flex-end" >
                            <MenuIcon cursor="pointer" onClick={handleClick} />
                            <Menu sx={menuList} id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ "aria-labelledby": "basic-button", }}>
                                <MenuItem sx={{ padding: '8px' }} >
                                    <Button onClick={downloadPdfDocument} sx={{ mr: { sm: '3px' } }} variant="outlined" className="button-style-productview">
                                        Download
                                        <img className="icons" alt="download icon" width={30} height={30} src="/assets/icon/download.svg" />
                                    </Button>
                                </MenuItem>
                                <MenuItem sx={{ padding: '8px' }} >
                                    <Button onClick={copyToClipboard} sx={{ mr: { sm: '3px' } }} variant="outlined" className="button-style-productview">
                                        Share
                                        <img className="icons" width={30} height={30} alt="share icon" src="/assets/icon/share.svg" />
                                    </Button>
                                </MenuItem>
                                <MenuItem sx={{ padding: '8px' }} >
                                    {
                                        data?.length === 3 ? (<></>) : (
                                            <Button className="button-style-productview" onClick={() => handleClickCompare(data)} variant="outlined">
                                                Compare
                                                <img className="icons" alt="compare icon" width={30} height={30} src="/assets/icon/compage.svg" />
                                            </Button>

                                        )
                                    }
                                </MenuItem>

                            </Menu>
                        </Grid>
                    </>
                }

            </Grid>
        </>
    );
};
export default ProductDetailsHead;