import React, { memo, useCallback, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Chip, InputAdornment } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const TypeAHeadAutocompleteInput = ({
  options,
  onValueChange,
  handleTypeaHeadApi,
  value,
  setValue,
}: any) => {
  const [open, setOpen] = React.useState(false);

  const handleChange = useCallback((newValue: any) => {
    onValueChange(newValue);
  }, [onValueChange]);

  useEffect(() => {
    if (value?.length !== 0 && value !== undefined) {
      const timer = setTimeout(() => {
        handleTypeaHeadApi(value);
      }, 500);
      return () => clearTimeout(timer);
    } else if (value?.length === 0) {
      onValueChange('');
    }
  }, [value]);

  return (
    <div>
      <Autocomplete
        size="small"
        id="combo-box-demo"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          if (/[^a-zA-Z0-9]/.test(newInputValue)) {
            // toast.error(`Special characters are not allowed, and please enter the product code only.`, {
            //     position: toast.POSITION.TOP_RIGHT,
            // });
          } else {
            setValue((prevList: any) => ({
              ...prevList,
              search: newInputValue,
            }));
          }
        }}
        value={value} 
        options={options || []}
        getOptionLabel={(option: any) => option}
        renderTags={(tagValue) =>
          tagValue.map((option, index) => (
            <Chip
              label={option}
              key={index}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            sx={{
              width: "100%",
              background: "#F7F7F7",
              borderRadius: "8px",
              marginLeft: { sm: '0px', xs: '0px' },
              border: "none",
              "& fieldset": {
                borderWidth: "0 !important",
              },
            }}
            value={value}
            autoComplete='chrome-off' placeholder="Search" variant="outlined" {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <img style={{ cursor: 'pointer' }} onClick={() => onValueChange(value)} width={30} height={30} alt='search icon' src="./assets/Search_alt_light.svg" />
                </InputAdornment>
              ),
              endAdornment: (
                <React.Fragment>
                  {value?.length === 0 ? null : (
                    <InputAdornment
                      position="end"
                      style={{ display: 'none' }}
                    />
                  )}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default memo(TypeAHeadAutocompleteInput);
