import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid,
    Box
} from "@mui/material";
const DeleteModal = ({openDelMod,handleClickCloseMain}:any)=>{
    return(
        <>
         <Dialog open={openDelMod}>
                <DialogContent >
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', width: '300px' }} >
                        <Box>
                            <Typography style={{ color: '#BA3D5F', fontFamily: 'Montserrat', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight: '36px', }} variant="body1" color='error'>Delete the product?</Typography>
                            <Typography mb={2} variant="body2" sx={{ fontFamily: 'Montserrat-light', fontSize: '11px', }}>You wont be able to recover it.</Typography>
                        </Box>
                        <Box justifyContent={'flex-end'}>
                            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => handleClickCloseMain('no')} />
                        </Box>
                    </Grid>
                    <Grid sx={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}>
                        <Button color="error" size="small" style={{ textTransform: "none", borderRadius: '5px', marginRight: '4px' }} onClick={() => handleClickCloseMain('yes')} variant="outlined" >Yes</Button>
                        <Button size="small" style={{ textTransform: "none", borderRadius: '5px', border: '1px solid #424242', background: '#424242', color: 'white' }} onClick={() => handleClickCloseMain('no')} variant="outlined" >No</Button>
                    </Grid>

                </DialogContent>
            </Dialog>
        </>
    );
};
export default DeleteModal;