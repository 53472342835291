import _ from "lodash";
export const setOptionsFormat = (options: any, labelKey: any) => {
    const result = _.map(options, (value) => {
        value.label = value[labelKey];
        return value;
    });
    return result;
};

export const setOptionsFormatObject = (options: any, labelKey: any) => {
    options.label = options[labelKey];
    return options;

};