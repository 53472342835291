import _ from "lodash";
const imgArr = [1, 2, 3, 4];

export const imageHandle = (urls: string[]): { glb: string; thumbImage: string[] } => {
    const [glbUrls, remainingUrls] = _.partition(urls, (url: string) => url.endsWith(".glb"));

    const imageObj: { glb: string; thumbImage: string[] } = {
        glb: glbUrls.length > 0 ? `${process.env.REACT_APP_BASE_URL_IMAGES}/${glbUrls[0]}` : '',
        thumbImage: remainingUrls.map((url: any) => `${process.env.REACT_APP_BASE_URL_IMAGES}/${url}`),
    };

    return imageObj;
    
};


export const imageEditHandle = async (urls: any) => {
    const img: any = [];
    const [glbUrls, remainingUrls] = _.partition(urls, (url: any) => url.endsWith(".glb"));
    if (glbUrls !== undefined && glbUrls.length > 0) {
        img.push({ id: 5, url: glbUrls[0] });
    }
    remainingUrls.forEach((image: any, index: any) => {
        img.push({ id: imgArr[index], url: image });
    });
    return await img;

};

