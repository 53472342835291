import useMediaQuery from '@mui/material/useMediaQuery';
export const mediaQueryXsm = () => {
    // const theme = useTheme();
    const matches = useMediaQuery('(max-width:360px)');
    return matches;
};
export const mediaQueryXs = () => {
    // const theme = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    return matches;
};

export const mediaQuerySm = () => {
    // const theme = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    return matches;
};

export const mediaQueryMd = () => {
    // const theme = useTheme();
    const matches = useMediaQuery('(min-width:1200px)');
    return matches;
};
export const mediaQueryLg = () => {
    // const theme = useTheme();
    const matches = useMediaQuery('(min-width:1536px)');
    return matches;
};
