export const INITIAL_STATES = {
    pageNumber: 0,
    pageSize: 12,
    type: 'international',
    search: '',
    tempSearch: '',
    filters: {
        materials: [],
        vendors: [],
        containers: [],
        shapes: [],
        products: [],
        skuRange: [],
    },
};

export const INITIAL_PRODUCT_DETAILS = {
    productCode: "",
    images: [],
    productAvailability: "",
    productType: "",
    bestSuite: [],
    mouldingProcess: "",
    material: "",
    containerType: "",
    shapes: [],
    skuRange: "",
    skuUnit: "",
    overFillCapacity: "",
    ofcUnit : "", //
    weight: "",
    weightUnit : "", //
    vendorName: "",
    price: "",
    productDescription: "",
};

export const stringValidation = (string: any) => {
    // console.log(/\s/g.test(string));
    return /\s/g.test(string);
};

export const Images = [
    "/assets/sample-products/3.png",
    "/assets/sample-products/3.png",
    "/assets/bottle.jfif",
    "/assets/sample-products/3.png",
];