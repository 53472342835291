import _ from "lodash";
export const validationToOperators = (data: any, validationType: any) => {
    const finalPayload: any = {};
    finalPayload.pageNumber = validationType === 'infinite' ? data?.pageNumber : 0;
    finalPayload.pageSize = data?.pageSize;
    finalPayload.type = data?.type;
    const result = _.pickBy(data?.filters, function (val) {
        return val.length > 0;
    });
    if (!_.isEmpty(result)) { finalPayload.filters = result; }
    if (data?.search !== '') { finalPayload.search = data?.search; }
    return finalPayload;
};