import * as React from 'react';
import { Card, CardContent, CardHeader, Grid } from "@mui/material";

import Skeleton from '@mui/material/Skeleton';
import _ from 'lodash';

export const SkeleTonLoader = ({ isProduct }: any) => {

    const data = [1, 2, 3, 4, 5, 6, 7, 8];
    return (
        <Grid pt={2} sx={{ paddingTop: isProduct ? '210px' : '0' }} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            {_.map(data, function (product: any, key: any) {
                return (<Grid key={key} item xs={12} lg={3} sm={6} md={3} >
                    <Card sx={{ boxShadow: 0 }}>
                        <CardContent>
                            <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                            <CardHeader sx={{ pb: 0.5, pl: 0 }}
                                title={<Skeleton
                                    animation="wave"

                                    width="80%"
                                    style={{ marginBottom: 6 }}
                                />} />
                            <Skeleton width="60%" />
                            <Skeleton width="60%" />
                        </CardContent>
                    </Card>
                </Grid>
                );
            })}
        </Grid>
    );

};

