import { useNavigate } from "react-router-dom";
import { Box, Grid, Button, Typography, Snackbar, Slide, SlideProps, SnackbarContent, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, FormGroup, Drawer, Checkbox, Stack, TextField, InputAdornment, Tooltip, Fab } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BarcodeIcon from '../../icons/barcodeIcon.svg';
import SuccessIcon from '../../icons/successIcon.svg';
import CloseIcon from '../../icons/closeIcon.svg';
import CrossIcon from '../../icons/crossIcon.png';
import { useEffect, useRef, useState } from "react";
import UploadIcon from '../../icons/uploadIcon.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import SearchIcon from '../../icons/SearchIcon.svg';
import './BarcodeListEmp.module.css';
import qs from 'qs';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';



const BarcodeEmployee = () => {
    const router = useNavigate();
    // const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [barcodeSnackbarOpen, setBarcodeSnackbarOpen] = useState(false);
    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [selectedBarcodeStatus, setSelectedBarcodeStatus] = useState<string[]>([]);
    const [rows, setRows] = useState<BarcodeItem[]>([]);
    const [filteredData, setFilteredData] = useState<BarcodeItem[]>([]);
    const [brandData, setBrandData] = useState<DataOption[]>([]);
    const [productData, setProductData] = useState<DataOption[]>([]);
    const [assigneeData, setAssigneeData] = useState<DataOption[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<string[]>([]);
    const [showFilteredData, setShowFilteredData] = useState(false);
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const nav = useNavigate();
    const size = 100;
    const [moreData, setMoreData] = useState(true);
    const [brandPage, setBrandPage] = useState(0);
    const [hasMoreBrandData, setHasMoreBrandData] = useState(true);
    const [showGoToTop, setShowGoToTop] = useState(false);


    interface BarcodeItem {
        sno: number,
        barcode: any,
        brandName: any,
        productName: any,
        description: any,
        sku: any,
        requestedDate: any,
        assignedDate: any,
        employeeName: any,
        empNo: any,
        assignee: any
    }
    interface DataOption {
        value: string;
        label: string;
    }


    // useEffect(() => {
    //     const debouncedScroll = debounce(handleScroll, 300);

    //     if (!showFilteredData) {
    //         window.addEventListener("scroll", debouncedScroll);
    //     }
    //     return () => {
    //         window.removeEventListener("scroll", debouncedScroll);
    //     };
    // }, [showFilteredData]);

    useEffect(() => {
        const debouncedScroll = debounce(handleScroll, 300);
        window.addEventListener("scroll", debouncedScroll);
        // Show "Go to Top" button after scrolling down
        const handleShowTopButton = () => {
            setShowGoToTop(window.scrollY > 500); // Adjust scroll position as needed
        };
        window.addEventListener("scroll", handleShowTopButton);

        return () => {
            window.removeEventListener("scroll", debouncedScroll);
            window.removeEventListener("scroll", handleShowTopButton);
        };
    }, [showFilteredData]);

    const handleGoToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        getBrandData();

        // const handler = setTimeout(() => {
        //     getBarcodeList(page, size);
        // }, 200);
        // return () => clearTimeout(handler);
    }, [page]);

    const getBarcodeList = async (page: number, size: number) => {
        if (!moreData) {
            setLoading(false);
            return;
        }

        const token = sessionStorage.getItem('AccessToken');
        try {
            const barcodeList = await axios.get(
                `https://packaginglibrary-uat-backend.citpl.info/api/v1/barcodes/user/getBarcodes?page=${page}&size=${size}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (Array.isArray(barcodeList?.data) && barcodeList?.data.length > 0) {
                setRows((prevData) => [...prevData, ...barcodeList.data]);
                setMoreData(true);
            } else {
                setMoreData(false);
            }
        } catch (err) {
            console.error('Error fetching employee barcode list:', err);
        } finally {
            setLoading(false);
        }
    };

    // const handleFilterScroll = () => {
    //     if (
    //         document.body.scrollHeight - 300 <
    //         window.scrollY + window.innerHeight
    //     ) {
    //         setPage((prevPage) => prevPage + 1);

    //     }
    // };


    const handleScroll = () => {
        if (!showFilteredData &&
            document.body.scrollHeight - 300 < window.scrollY + window.innerHeight) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleBrandScroll = async (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        const buffer = 100;

        if (scrollHeight - scrollTop <= clientHeight + buffer && !loading && hasMoreBrandData) {
            setLoading(true);
            await getBrandData(brandPage + 1);
            setBrandPage(prev => prev + 1);
            setLoading(false);
        }
    };

    function debounce(func: any, delay: any) {
        let timeoutId: any;
        return function (...args: any) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    // useEffect(() => {
    //     const debouncedScroll = debounce(handleScroll, 300);
    //     window.addEventListener("scroll", debouncedScroll);

    //     return () => {
    //         window.removeEventListener("scroll", debouncedScroll);
    //     };
    // }, []);

    const formatDate = (dateString: any) => {
        if (!dateString) {
            return '-';
        }
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    // const handleOpenSnackbar = () => {
    //     setSnackbarOpen(true);
    // };

    // const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //     setSnackbarOpen(false);
    // };

    const handleCloseBarcodeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setBarcodeSnackbarOpen(false);
    };
    type Anchor = 'right';

    const SlideTransition = (props: SlideProps) => {
        return <Slide {...props} direction="left" />;
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'brand' | 'product') => {
        const value = event.target.value;

        if (type === 'brand') {
            setSelectedBrands((prev) =>
                prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
            );
        } else if (type === 'product') {
            setSelectedProducts((prev) =>
                prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
            );
        }
    };

    const handleBarcodeStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedBarcodeStatus((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
    };

    useEffect(() => {
        if (selectedBrands.length > 0) {
            getProductData(selectedBrands);
        } else {
            setProductData([]);
        }
    }, [selectedBrands]);

    useEffect(() => {
        if (selectedBrands.length > 0) {
            getProductData(selectedBrands);
            setSelectedProducts([]);
        } else {
            setProductData([]);
            setSelectedProducts([]);
        }
    }, [selectedBrands]);


    const list = (anchor: Anchor) => (
        <Box sx={{ width: 350, overflowY: 'scroll' }} role="presentation">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1.5vh' }}>
                <Typography variant="h6">Filters</Typography>
                <Box>
                    <span onClick={() => setFilterDrawerOpen(false)} style={{ color: 'red', cursor: 'pointer' }}>Close</span>
                    <img onClick={() => setFilterDrawerOpen(false)} src={CloseIcon} style={{ cursor: 'pointer' }} alt="close" />
                </Box>
            </Box>
            <Grid item xs={3} style={{ marginInline: '3vh' }}>
            </Grid>
            <Accordion sx={{ outline: 'none', boxShadow: 'none', marginBlockStart: '2vh' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="body1">Brand</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-2vh', marginBlockEnd: '-3vh' }}>
                    <Box
                        sx={{
                            maxHeight: '120px',
                            overflowY: 'auto',
                        }}
                        onScroll={handleBrandScroll}
                    >
                        <FormGroup>
                            {brandData.map(option => (
                                <FormControlLabel
                                    sx={{
                                        marginBlock: '-3.5px',
                                        whiteSpace: 'normal',       // Allows wrapping onto multiple lines
                                        wordBreak: 'break-word',    // Breaks words if they are too long
                                    }}
                                    key={option.value}
                                    control={
                                        <Checkbox
                                            checked={selectedBrands.includes(option.value)}
                                            onChange={(e) => handleCheckboxChange(e, 'brand')}
                                            value={option.value}
                                        />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </FormGroup>
                        {loading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1vh' }}>
                                <Typography variant="body2">Loading more...</Typography>
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ outline: 'none', boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="body1">Product</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-2vh', marginBlockEnd: '-3vh' }}>
                    <Box
                        sx={{
                            maxHeight: '120px',
                            overflowY: 'auto',
                        }}
                    >
                        <FormGroup>
                            {productData.map(option => (
                                <FormControlLabel
                                    sx={{ marginBlock: '-7px' }}
                                    key={option.value}
                                    control={
                                        <Checkbox
                                            checked={selectedProducts.includes(option.value)}
                                            onChange={(e) => handleCheckboxChange(e, 'product')}
                                            value={option.value}
                                        />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </FormGroup>
                        {loading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1vh' }}>
                                <Typography variant="body2">Loading more...</Typography>
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ outline: 'none', boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="body1">Barcode</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-2vh', marginBlockEnd: '-3vh' }}>
                    <FormGroup>
                        {['Assigned', 'UnAssigned'].map(option => (
                            <FormControlLabel
                                sx={{ marginBlock: '-7px' }}
                                key={option}
                                control={
                                    <Checkbox
                                        checked={selectedBarcodeStatus.includes(option)}
                                        onChange={handleBarcodeStatusChange}
                                        value={option}
                                    />
                                }
                                label={option}
                            />
                        ))}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
            <Stack spacing={2} direction="row" style={{ marginInlineStart: '25vh', marginBlockStart: '5vh' }}>
                <Button variant="outlined" sx={{ textTransform: 'none' }} onClick={handleClearFilters}>Clear</Button>
                <Button variant="contained" sx={{ textTransform: 'none' }} onClick={handleApplyClick}>Apply</Button>
            </Stack>
        </Box>
    );



    // const getFilteredData = async (brandNames: string[], productNames: string[], assignee: string[]) => {
    //     const token = sessionStorage.getItem('AccessToken');
    //     try {
    //         const filteredData = await axios.post(`https://packaginglibrary-uat-backend.citpl.info/api/v1/barcodes/user/filterOfBarcodes?brandNames=${brandNames}&productNames=${productNames}&statuses=${assignee}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         });
    //         setFilteredData(filteredData.data);
    //         setShowFilteredData(true);
    //         setFilterDrawerOpen(false);
    //     } catch (error) {
    //         console.error('Error fetching filtered data:', error);
    //     }
    // };

    const getFilteredData = async (brandNames: string[], productNames: string[], statuses: string[]) => {
        const token = sessionStorage.getItem('AccessToken');
        try {
            const filteredData = await axios.post(
                `https://packaginglibrary-uat-backend.citpl.info/api/v1/barcodes/user/filterOfBarcodes`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        brandNames,
                        productNames,
                        statuses
                    },
                    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
                }
            );
            setFilteredData(filteredData.data);
            setShowFilteredData(true);
            setFilterDrawerOpen(false);
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    };



    // const getBrandData = async () => {
    //     const token = sessionStorage.getItem('AccessToken');
    //     try {
    //         const brandData = await axios.get('https://packaginglibrary-uat-backend.citpl.info/api/v1/barcodes/user/brandsOfBarcodes', {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             }
    //         }
    //         );
    //         const formattedData = brandData.data.map((item: string) => ({
    //             value: item,
    //             label: item
    //         }));
    //         setBrandData(formattedData);
    //     } catch (error) {
    //         console.error('Error fetching brand data:', error);
    //     }
    // };

    const getBrandData = async (page = 0) => {
        const token = sessionStorage.getItem('AccessToken');
        try {
            const response = await axios.get(`https://packaginglibrary-uat-backend.citpl.info/api/v1/barcodes/user/brandsOfBarcodes`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { page } // Use page parameter for pagination
            });
            const formattedData = response.data.map((item: string) => ({ value: item, label: item }));
            setBrandData(prev => [...prev, ...formattedData]); // Append new data
            if (formattedData.length === 0) {
                setHasMoreBrandData(false);
            }
        } catch (error) {
            console.error('Error fetching brand data:', error);
        }
    };


    // const getProductData = async (brandNames: string[]) => {
    //     const token = sessionStorage.getItem('AccessToken');
    //     try {
    //         const responses = await Promise.all(
    //             brandNames.map((brandName) =>
    //                 axios.get(`https://packaginglibrary-uat-backend.citpl.info/api/v1/barcodes/user/productsOfBarcodes`, {
    //                     params: {
    //                         brandName
    //                     },
    //                     headers: {
    //                         Authorization: `Bearer ${token}`
    //                     }
    //                 })
    //             )
    //         );
    //         const formattedData = responses.flatMap(response =>
    //             response.data.map((item: string) => ({
    //                 value: item,
    //                 label: item
    //             }))
    //         );
    //         setProductData(formattedData);
    //     } catch (error) {
    //         console.error('Error fetching product data:', error);
    //     }
    // };

    const getProductData = async (brandNames: string[], page = 0) => {
        const token = sessionStorage.getItem('AccessToken');
        try {
            const responses = await Promise.all(
                brandNames.map(brandName =>
                    axios.get(`https://packaginglibrary-uat-backend.citpl.info/api/v1/barcodes/user/productsOfBarcodes`, {
                        headers: { Authorization: `Bearer ${token}` },
                        params: { brandName }
                    })
                )
            );
            const formattedData = responses.flatMap(response =>
                response.data.map((item: string) => ({ value: item, label: item }))
            );
            setProductData(formattedData);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    useEffect(() => {
        if (selectedBrand.length > 0) {
            getProductData(selectedBrand);
        } else {
            setProductData([]);
        }
    }, [selectedBrand]);

    // useEffect(() => {
    //     if (selectedBrand.length > 0 && selectedCheckboxes.some((item) => productData.some((product) => product.value === item))) {
    //         const selectedProducts = selectedCheckboxes.filter((item) =>
    //             productData.some((product) => product.value === item)
    //         );
    //     } else {
    //         setAssigneeData([]);
    //     }
    // }, [selectedCheckboxes, productData]);

    const handleApplyClick = () => {
        getFilteredData(selectedBrands, selectedProducts, selectedBarcodeStatus);
    };

    const handleClearFilters = () => {
        setSelectedBrands([]);
        setSelectedProducts([]);
        setSelectedBarcodeStatus([]);
        setSelectedBrand([]);
        setFilteredData([]);
        setRows([]);
        setShowFilteredData(false);
        getBarcodeList(page, size);
    };


    const getSearchData = async (searchText: any) => {
        const token = sessionStorage.getItem('AccessToken');
        setRows([]);
        try {
            const res = await axios.get(`https://packaginglibrary-uat-backend.citpl.info/api/v1/barcodes/user/searchByBarcodeAndBrandAndProduct?searchText=${searchText}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setRows(res.data);
        }
        catch (error) {
            console.log('Error fetching Data', error);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.length == 0) {
                setRows([]);
            }
        }, 200);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm) {
                getSearchData(searchTerm);
            } else {
                getBarcodeList(page, size);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, page]);

    return (
        <Grid item xs={8} sx={{ marginTop: '80px' }}>
            <Box>
                <Box className="back-icon" sx={{ marginBlockEnd: '1vh', display: 'flex', alignItems: 'center' }}>
                    <img className="icon" onClick={() => router('/barcodeListEmp')} alt="back icon" style={{ marginLeft: '4vh', height: '24px', width: '24px', cursor: 'pointer' }} src="/assets/icon/back_arrow.svg" />
                    <Typography onClick={() => router('/barcodeListEmp')} sx={{ marginLeft: '0%', fontSize: '17px', cursor: 'pointer' }}>Back</Typography>
                </Box>
                <Grid container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', marginBlockEnd: '4vh' }}>
                    <Grid sx={{ flexBasis: "60%", marginLeft: '5vh' }} item xs={6}>
                        <Typography variant="h6">Barcode Database</Typography>
                    </Grid>
                    <Grid sx={{ flexBasis: "40%", display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginInlineEnd: '5%', alignItems: 'center' }} item xs={4}>
                        <Grid item xs={6}>
                            <TextField
                                id="fsrch"
                                name="fsrch"
                                placeholder="Search"
                                size="small"
                                style={{ backgroundColor: '#f0f0f0', borderRadius: '2vh' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={SearchIcon} alt="search" style={{ marginInlineStart: '-1vh' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    }
                                }}
                                onChange={handleSearchChange}
                            />
                        </Grid>
                        <img className="icon" onClick={() => setFilterDrawerOpen(true)} alt="filter icon" style={{ marginLeft: '-8vh', height: '24px', width: '24px', cursor: 'pointer' }} src="/assets/filterIcon.svg" />
                        {/* <input
                            type="file"
                            accept=".xlsx,.csv"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileUpload}
                        /> */}
                        {/* <Button sx={{ borderRadius: '1vh', textTransform: 'none', marginInlineStart: '-8vh', marginRight: '1vh' }} variant="outlined" onClick={handleUploadClick} endIcon={<img src={UploadIcon} alt="UploadIcon" />}> Upload</Button> */}
                    </Grid>
                </Grid>
                <TableContainer className="custom-scrollbar" component={Paper} style={{ marginInlineStart: '2vh', width: '99%', overflowY: 'scroll', overflowX: 'scroll' }}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableHead style={{ backgroundColor: '#f0f0f0' }}>
                            <TableRow style={{ position: 'sticky', top: 0, backgroundColor: '#f0f0f0', zIndex: 1 }}>
                                <TableCell sx={{ fontWeight: 'bold' }}>S.No</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>GTIN</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Brand</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Product</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>SKU</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Requested Date</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Approved Date</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Requestee Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Employee ID </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Assignee</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(showFilteredData ? filteredData : rows).length > 0 ? (
                                (showFilteredData ? filteredData : rows).map((barcodeList, index) => (
                                    <TableRow key={barcodeList.barcode}>
                                        <TableCell sx={{ padding: '3vh' }} align="right">{index + 1}</TableCell>
                                        <TableCell>
                                            {barcodeList.barcode ? (
                                                <>
                                                    <span>{barcodeList.barcode}</span>&nbsp;&nbsp;
                                                    <img
                                                        // onClick={() => handleOpenSnackbar()} 
                                                        src={BarcodeIcon} alt="barcode" style={{ cursor: 'pointer' }} />
                                                </>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }} component="th" scope="row">
                                            {barcodeList.brandName}
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.productName}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <Tooltip title={barcodeList.description} arrow placement="left-start">
                                                <span>{barcodeList.description}</span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.sku}</TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{formatDate(barcodeList.requestedDate)}</TableCell>
                                        <TableCell>
                                            {barcodeList.assignedDate ? (
                                                <span style={{ color: 'green', backgroundColor: '#1C98191A', borderRadius: '5px', padding: '4px' }}>{formatDate(barcodeList.assignedDate)}</span>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.employeeName}</TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.empNo}</TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.assignee}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={11} sx={{ textAlign: 'center', padding: '3vh' }}>
                                        No Data
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Box>
            {/* <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} TransitionComponent={SlideTransition}>
                <SnackbarContent {...snackbarContentProps} />
            </Snackbar> */}
            {/* <Snackbar open={barcodeSnackbarOpen} autoHideDuration={3000} onClose={handleCloseBarcodeSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: 'white',
                        color: messageType === 'success' ? 'green' : 'red',
                        borderRadius: '8px',
                        padding: '1.5vh',
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                    }
                }}
                TransitionComponent={SlideTransition}>
                <SnackbarContent message={(
                    <div style={{ display: 'flex', alignItems: 'center', background: 'white' }}>
                        <img src={messageType === 'success' ? SuccessIcon : CrossIcon} alt="status icon" />
                        <span style={{ marginInline: '1vh' }}>
                            {snackbarMessage}
                        </span>
                        <img src={CloseIcon} onClick={handleCloseBarcodeSnackbar} style={{ cursor: 'pointer' }} alt="close" />
                    </div>
                )}
                />
            </Snackbar> */}
            <Drawer

                anchor='right'
                open={filterDrawerOpen}
                onClose={() => setFilterDrawerOpen(false)}
            >
                {list('right')}
            </Drawer>

            {showGoToTop && (
                <Fab
                    color="primary"
                    size="small"
                    aria-label="go to top"
                    onClick={handleGoToTop}
                    sx={{
                        position: 'fixed',
                        bottom: '2rem',
                        right: '2rem',
                        zIndex: 1000,
                    }}
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            )}
        </Grid >
    );
};

export default BarcodeEmployee;
