import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
export const pdfDownloader = async (page: any) => {

    const input: any = document.getElementById('box-wrapper');
    try {
        const canvas = await html2canvas(input , { useCORS: true, allowTaint: true });
        const pdf:any = new jsPDF('p', 'px', 'a3');
        const scaleFactor = pdf.internal.pageSize.getWidth() / canvas.width;
        const padding = 10;
        const marginTop = 0; // Set your desired top margin value
        const contentX = padding;
        const contentY = padding + marginTop; // Adjusted to include the top margin
        const contentWidth = canvas.width * scaleFactor - 2 * padding;
        await pdf.addImage(canvas, 'PNG', contentX, contentY, contentWidth, canvas.height * scaleFactor);
        await pdf.save(`${page}.pdf`);
    } catch (error) {
        console.error('An error occurred:', error);
    }

};
// const dowloadPdf = () => {
//     console.log('ok');
//     const input: any = document.getElementById('page');
//     html2canvas(input).then((canvas) => {
//         const imgData = canvas.toDataURL('image/png');
//         const pdf = new jsPDF('p', 'mm', 'a4', true);
//         const pdfWidth = pdf.internal.pageSize.getWidth();
//         const pdfHeight = pdf.internal.pageSize.getHeight();
//         const imgWidth = canvas.width;
//         const imgHeight = canvas.height;
//         const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
//         const imgX = (pdfWidth - imgWidth * ratio) / 2;
//         const imgY = 30;
//         pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
//         pdf.save('detail-page.pdf');
//     });

// };