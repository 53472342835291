import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";

const Modal = ({ open, message, content, handleClose }: any) => {
  return (
    <div>
      <Dialog open={open}>
        <DialogContent style={{ padding: '20px 60px', textAlign: "center" }}>
          <div className="modal-title">
            <Typography variant="body1" sx={{ color: 'rgba(28, 152, 25, 1)' }}>{message}</Typography>
          </div>
          <div className="modal-content">
            <Typography mb={2} variant="body2" sx={{ fontFamily: 'Montserrat-light' }}>{content}</Typography>
          </div>
          <div className="modal-btn">
            <Button
              size="small"
              style={{ textTransform: "none", color: '#1C9819' }}
              onClick={handleClose}
              variant="outlined"
              color="success"
            >
              OK
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Modal;
