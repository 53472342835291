import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import FormInput from "../commons/input";
import { memo } from "react";
import { productType, productAvailability } from "../../constants/drop-down-contants";
import { setOptionsFormat } from "../commons/label-convert";
const inputStyles = {
    display: { xs: "block", md: 'flex', sm: 'flex' }, justifyContent: 'space-between',
};
const FormInputComponent = ({ handleFormInputChange, errors, data, dropdownData }: any) => {

    return (
        <>
            <Box sx={{ padding: { md: "20px", sm: "10px" } }} mt={5}>
                <Grid p={1} md={12} sx={inputStyles}>
                    <FormInput label="productAvailability" placeholder="Select Product Availability" field="Product Availability" type="autocomplete" name="productAvailability" options={setOptionsFormat(productAvailability, "productAvailabilityName")} handleFormInputChange={handleFormInputChange} value={data?.productAvailability} error={Boolean(errors['productAvailability'])} helperText={errors['productAvailability'] || ""} />
                </Grid>
                <Grid p={1} md={12} sx={inputStyles}>
                    <FormInput label="productType" placeholder="Select Product Type" field="Product Type" type="autocomplete" name="productType" handleFormInputChange={handleFormInputChange} options={setOptionsFormat(productType, "productTypeName")} value={data?.productType} error={Boolean(errors['productType'])} helperText={errors['productType'] || ""} />
                </Grid>
                <Grid p={1} md={12} sx={inputStyles}>
                    <FormInput label="bestSuite" placeholder="Select Best Suited For" field="Best Suited For" type="autocomplete" name="bestSuite" multiple="true" handleFormInputChange={handleFormInputChange} options={setOptionsFormat(dropdownData?.products, "productName")} value={data?.bestSuite} error={Boolean(errors['bestSuite'])} helperText={errors['bestSuite'] || ""} />
                </Grid>
                <Grid p={1} md={12} sx={inputStyles}>
                    <FormInput label="mouldingProcess" placeholder="Select Moulding Process" field="Moulding Process" type="autocomplete" name="mouldingProcess" handleFormInputChange={handleFormInputChange} options={setOptionsFormat(dropdownData?.mouldings, "mouldingName")} value={data?.mouldingProcess} error={Boolean(errors['mouldingProcess'])} helperText={errors['mouldingProcess'] || ""} />
                </Grid>
                <Grid p={1} md={12} sx={inputStyles}>
                    <FormInput label="material" placeholder="Select Material" field="Material" type="autocomplete" name="material" handleFormInputChange={handleFormInputChange} value={data?.material} options={setOptionsFormat(dropdownData?.materials, "materialName")} error={Boolean(errors['material'])} helperText={errors['material'] || ""} />
                </Grid>
                <Grid p={1} md={12} sx={inputStyles}>
                    <FormInput label="containerType" placeholder="Select Container Type" field="Container Type" type="autocomplete" name="containerType" handleFormInputChange={handleFormInputChange} options={setOptionsFormat(dropdownData?.containers, "containerName")} value={data?.containerType} error={Boolean(errors['containerType'])} helperText={errors['containerType'] || ""} />
                </Grid>
                <Grid p={1} md={12} sx={inputStyles}>
                    <FormInput label="shapes" placeholder="Select Shapes" field="Shapes" type="autocomplete" name="shapes" handleFormInputChange={handleFormInputChange} value={data?.shapes} options={setOptionsFormat(dropdownData?.shapes, "shapeName")} error={Boolean(errors['shapes'])} helperText={errors['shapes'] || ""} />
                </Grid>
                <Grid container p={1} sx={{ display: 'flex', justifyContent: 'space-between' }} md={12}>
                    <Grid sm={12} md={4}>
                        <Typography>SKU Range</Typography>
                    </Grid>
                    <Grid container sm={12} md={8} sx={inputStyles}>
                        <Grid alignItems={'center'} sm={12} md={7}>
                            <FormInput field="SKU Range" placeholder="Enter SKU Range" label="skuRange" type="text" name="skuRange" handleFormInputChange={handleFormInputChange} value={data?.skuRange} error={Boolean(errors['skuRange'])} helperText={errors['skuRange'] || ""} />
                        </Grid>
                        <Grid mt={{ xs: 2, md: 0, sm: 3 }} sm={12} md={5}>
                            <FormInput field="SKU Unit" placeholder="Select SKU Unit" label="skuUnit" type="autocomplete" name="skuUnit" handleFormInputChange={handleFormInputChange} value={data?.skuUnit} options={setOptionsFormat(dropdownData.units, "unitName")} error={Boolean(errors['skuUnit'])} helperText={errors['skuUnit'] || ""} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container p={1} sx={{ display: 'flex', justifyContent: 'space-between' }} md={12}>
                    <Grid sm={12} md={4}>
                        <Typography>Over Fill Capacity</Typography>
                    </Grid>
                    <Grid container sm={12} md={8} sx={inputStyles}>
                        <Grid alignItems={'center'} sm={12} md={7}>
                            <FormInput placeholder="Enter Over Fill Capacity" type="text" name="overFillCapacity" handleFormInputChange={handleFormInputChange} value={data?.overFillCapacity} error={Boolean(errors['overFillCapacity'])} helperText={errors['overFillCapacity'] || ""} />
                        </Grid>
                        <Grid mt={{ xs: 2, md: 0, sm: 3 }} sm={12} md={5}>
                            <FormInput placeholder="Select Over Fill Capacity Unit" type="autocomplete" name="ofcUnit" handleFormInputChange={handleFormInputChange} value={data?.ofcUnit} options={setOptionsFormat(dropdownData.units, "unitName")} error={Boolean(errors['ofcUnit'])} helperText={errors['ofcUnit'] || ""} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container p={1} sx={{ display: 'flex', justifyContent: 'space-between' }} md={12}>
                    <Grid sm={12} md={4}>
                        <Typography>Weight</Typography>
                    </Grid>
                    <Grid container sm={12} md={8} sx={inputStyles}>
                        <Grid alignItems={'center'} sm={12} md={7} padding={'0px'}>
                            <FormInput placeholder="Enter Weight" type="text" name="weight" handleFormInputChange={handleFormInputChange} value={data?.weight} error={Boolean(errors['weight'])} helperText={errors['weight'] || ""} />
                        </Grid>
                        <Grid mt={{ xs: 2, md: 0, sm: 3 }} sm={12} md={5}padding={'0px'}>
                            <FormInput placeholder="Select Weight Unit" type="autocomplete" name="weightUnit" handleFormInputChange={handleFormInputChange} value={data?.weightUnit}  options={[{ label: 'G', value: 'G' }]}   error={Boolean(errors['weightUnit'])} helperText={errors['weightUnit'] || ""} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid p={1} md={12} sx={inputStyles}>
                    <FormInput label="vendorName" placeholder="Select Vendor Name" field="Vendor Name" type="autocomplete" name="vendorName" handleFormInputChange={handleFormInputChange} value={data?.vendorName} error={Boolean(errors['vendorName'])} options={setOptionsFormat(dropdownData.vendors, "vendorName")} helperText={errors['vendorName'] || ""} />
                </Grid>
                <Grid p={1} md={12} sm={12} sx={inputStyles}>
                    <FormInput label="price" placeholder="Enter Price" field="Price" type="text" name="price" handleFormInputChange={handleFormInputChange} value={data?.price} error={Boolean(errors['price'])} helperText={errors['price'] || ""} />
                </Grid>

            </Box>

        </>
    );
};
export default memo(FormInputComponent);