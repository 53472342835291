
export const compareStyles = {
    position: 'relative',
    marginTop: '30px',
    marginLeft: '10px',
    bottom: { xs: '10px' },
    flex: ' 0 0 auto',
    width: '100%',


    '& .box-wrapper': {

        border: "1px solid #000000",
        padding: "20px",
        borderRadius: "12px",
        height: '100%',
        flexWrap: 'nowrap',

        marginLeft: { xs: 1 }
    },
    "& .image-scroll-container ": {
        overflowX: { xs: 'auto', sm: 'auto', md: 'initial' },
        whiteSpace: { xs: 'nowrap', sm: 'nowrap', md: 'initial' },
        flexDirection: { xs: 'row', sm: 'row', md: 'initial' },
        justifyContent: { xl: 'end' }
        // justifyContent: 'flex-start', /* Adjust as needed */
    },
    "& .scroll-content": {
        display: { xs: 'flex', sm: 'flex', md: 'initial' },
        flexWrap: { xs: 'nowrap', sm: 'nowrap', md: 'initial' },
    },
    '& .compare-close-icon': {
        position: 'absolute',
        top: { md: '-12px', xs: '-5px', sm: '-11px' },
        right: '-9px',
        color: 'red',
        width: '20px',
        cursor: 'pointer'

    },
    '& .compare-images': {
        height: '15vh', borderRadius: '6px',
        width: '100%',
        cursor: 'pointer'
    },
    '& .images-content': {
        color: 'rgba(103, 103, 103, 1)',
        fontSize: "14px",
        fontWeight: "200",
        marginTop: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        fontFamily: 'Montserrat-light',
    },
    '& .vendor-content': {
        display: "flex", alignItems: "center", textAlign: "center",
        margin: '20px 0px',
        '& .MuiGrid-root': {
            display: "flex", fontSize: "0.7rem", alignItems: "center",
            '& .vendor-typo': {
                color: '#000',
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                fontSize: { xs: '15px', sm: '18px', md: '18px' },
            },
        },
        '& .label-wrapper': {
            fontSize: { xs: '13px', sm: '14px', md: '14px' },
            color: 'rgba(66, 66, 66, 1)',
            fontFamily: 'Montserrat-light',
        },
    },
    '& .vendor-text': {
        fontSize: { xs: '11px', sm: '14px', md: '14px' },
        color: 'rgba(66, 66, 66, 1)',
        fontFamily: 'Montserrat-light',

    },

    '& .vendor-text-sub': {
        color: "rgba(0, 0, 0, 1)",
        fontSize: "1.1rem",
        fontWeight: "400",
        textAlign: "left"

    },
    '& .vendor-text-flex': {
        color: "rgba(0, 0, 0, 1)",
        fontSize: "1.7rem",
        fontWeight: "400",
        '& span': {
            fontSize: "12px",
        },


    },
    '& .form-fields': {
        color: "rgba(0, 0, 0, 1)",
        fontSize: "16px",
        fontWeight: "200",
        textAlign: "left",
    },
    '& .form-values': {
        color: "rgba(0, 0, 0, 1)",
        fontSize: "16px",
        fontWeight: "400",
        textAlign: "left",
    },
    '& .form-desc': {
        color: "rgba(0, 0, 0, 1)",
        fontSize: "16px",
        fontWeight: "400",
        textAlign: "left",
        lineHeight: '28px',
        letterSpacing: '0em',
    },
    '& .empty-box-wrapper': {
        // marginTop: { md: "30px", sm: '30px', xs: '30px' },
        height: '100%',
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        p: 2,
        border: "1px dashed #676767",
        borderRadius: "14px",
        cursor: 'pointer'

    },
    '& .avatar-icon': {
        margin: '0 auto',
        justifyContent: 'center',
        cursor: 'pointer',
        textAlign: 'center'
    },
    '& .glb-image-container': {
        width: '-webkit-fill-available',
        height: { xs: '40vh', md: 'auto', sm: '40vh', lg: 'auto' }
    },

    '& .glb-image': {
        position: 'relative',
        boxShadow: 'rgba(217, 217, 217, 1)',
        height: "98.5%", textAlign: "center", alignItems: "center",
    },

    '& .slide-image': {
        borderRadius: "10px",
        width: "100px",
        height: "110px",
        position: 'relative',
        cursor: 'pointer',
    },
    '& .hover-container': {
        position: 'absolute', cursor: 'pointer', top: '55px', left: '50px', transform: 'translate(-50%, -50%)', backgroundColor: "rgba(0, 0, 0, 0.6)", color: "#fff", padding: "49px", borderRadius: "10px", width: "150px", height: "110px"
    },

    '& .MuiTypography-h5': {
        color: '#000',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        fontSize: { xs: '14px', sm: '18px', md: '22px' },
        wordWrap: 'break-word',
    },
    '& .MuiTypography-body2': {
        color: '#000',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        fontSize: { xs: '14px', sm: '18px', md: '22px' },
        wordWrap: 'break-word',
        textAlign: 'justify'
    },
    '& .MuiTypography-h4': {
        color: '#000',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        fontSize: { xs: '14px', sm: '18px', md: '25px' },
    },

};
