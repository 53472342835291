export const FIRST_LEVEL_FILTER_OPTIONS = [
    {
      title: "Products",
      name:"products",
      options: ["Shampoo", "Soap"],
      icon:false
    },
    {
      title: "Shapes",
      name:"shapes",
      icon:false,
      options: ["Cylinder", "Flat", "Oval"],
    },
    {
      title: "Containers",
      name:"containers",
      icon:false,
      options: ["Opaque", "Transparent"],
    },
    {
      title: "SKU Range",
      name:"skuRange",
      icon:false,
      options: ["0-20ml", "20-50ml", "50-100ml"],
    },
    {
      title: "Materials",
      name:"materials",
      icon:true,
      options: ["HDPE", "PET","PP","ACRYLIC","PET-G"],
    },
    {
      title: "Vendors",
      name:"vendors",
      icon:true,
      options: [ "Weener Empire Pvt. Ltd", "Sarvottam Polymers Pvt. Ltd", "Takemoto", "AG Poly Pack", "Radcom", "Acron Plast", "Supple Pack / National Vinyl", "Vimal Plastics", "Mold-tek", "Sibi Polymers", "Manjushree", "Mahika", "Aptar" ],
    },
  ];
  export const SKURANGEFILTER = [
    {skuRangeName:"0-20ml",label:"0-20ml"},
    {skuRangeName:"20-50ml",label:"20-50ml"},
    {skuRangeName:"50-100ml",label:"50-100ml"},
    {skuRangeName:"100-200ml",label:"100-200ml"},
    {skuRangeName:"200-500ml",label:"200-500ml"},
    {skuRangeName:"500-700ml",label:"500-700ml"},
  ];