import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";


export default function Page404() {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh'
            }}
        >
            <Container maxWidth="md">
                <Grid alignItems={'center'} justifyContent={'center'} container spacing={2}>
                    <Grid xs={3}>
                        <img
                            src="./assets/eror.png"
                            alt=""
                            width={150} height={150}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <Typography variant="h4">
                            404
                        </Typography>
                        <Typography variant="h5">
                            The page you’re looking for doesn’t exist.
                        </Typography>
                        <Grid mt={1}>
                        <Button color='success' onClick={() => navigate('/')} variant="contained">Back Home</Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    );
}